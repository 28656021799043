import styled from 'styled-components';

export const SkillsUsed = styled.div`
  margin: 0.5rem;
  border: 1px dashed #5a67d8;
  border-radius: 1rem;
`;

export const TC = styled.div`
  margin: 0.5rem 0;
`;
